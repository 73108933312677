import { FeatureToggle } from "raci-react-library-npe";
import { ExternalLink } from "../../types";

export const DEFAULT_SHIELD_ENVIRONMENT = "Default";
export const RECOIL_PREFIX = "RECOIL";

export const STORAGE_KEY_EXTERNAL_LINK = "STORAGE_KEY_EXTERNAL_LINK";

export const HTTP_HEADER_KEY_SHIELD_ENVIRONMENT = "Environment";
export const HTTP_HEADER_KEY_SOURCE_SYSTEM = "SourceSystem";

export const HTTP_QUERYSTRING_TESTLAUNCHPAGE = "TLP";

export const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID ?? "";
export const OAUTH_AUTHORITY = process.env.REACT_APP_OAUTH_AUTHORITY ?? "";

export const getBypassHeaders = () => {
  const headers: any = {};
  headers[HTTP_HEADER_KEY_SHIELD_ENVIRONMENT] = process.env.REACT_APP_DEFAULT_SHIELD_ENVIRONMENT;
  headers[HTTP_HEADER_KEY_SOURCE_SYSTEM] = process.env.REACT_APP_SOURCE_SYSTEM_NAME;

  return headers;
};

export const FEATURE_TOGGLES = JSON.parse(process.env.REACT_APP_FEATURE_TOGGLES || "[]") as FeatureToggle[];
export const SHIELD_ENVIRONMENTS = JSON.parse(process.env.REACT_APP_SHIELD_ENVIRONMENTS || "[]") as string[];

const SPARK_APP_LINKS_DEV: ExternalLink[] = [
  {
    displayName: "Motor Collision Claims",
    url: "https://claimsmotorcollision-dev.racinsurance.ractest.com.au/claims/motor/car-accident/before-you-start",
  },
  {
    displayName: "Home General Damage Claims",
    url: "https://claimshomegeneraldamage-dev.racinsurance.ractest.com.au/claims/home/general/storm-damage-claim",
  },
  {
    displayName: "How You Pay",
    url: "https://cmp-dev.racinsurance.ractest.com.au/policy/payment/how-you-pay",
  },
  {
    displayName: "Policy Cancellations",
    url: "https://pco-dev.racinsurance.ractest.com.au/Policy/Cancel",
  },
  {
    displayName: "Claims Triage - Motor",
    url: "https://ctr-dev.racinsurance.ractest.com.au/claims/motor/what-are-you-claiming-for",
  },
  {
    displayName: "Claims Triage - Home",
    url: "https://ctr-dev.racinsurance.ractest.com.au/claims/home/building-and-contents",
  },
  {
    displayName: "Motor Glass Claims",
    url: "https://claimsmotorglass-dev.racinsurance.ractest.com.au/claims/motor/glass/before-you-start",
  },
  {
    displayName: "Caravan Endorsement",
    url: "https://caravanendorsement-dev.racinsurance.ractest.com.au/policy/caravan/manage",
  },
  {
    displayName: "Motor Endorsement",
    url: "https://motorendorsement-dev.racinsurance.ractest.com.au/policy/motor/manage",
  },
  {
    displayName: "Confirm Details MFE",
    url: "https://confirmdetails-dev.racinsurance.ractest.com.au/confirmdetails",
  },
  {
    displayName: "Claims Servicing - Upload Invoice",
    url: "https://claimsservicing-dev.racinsurance.ractest.com.au/claims/servicing/invoice-or-quote",
  },
  {
    displayName: "Make A Payment",
    url: "https://makeapayment-dev.racinsurance.ractest.com.au/member/pay",
  },
];

const SPARK_APP_LINKS_SIT: ExternalLink[] = [
  {
    displayName: "Motor Collision Claims",
    url: "https://claimsmotorcollision-sit.racinsurance.ractest.com.au/claims/motor/car-accident/before-you-start",
  },
  {
    displayName: "Home General Damage Claims",
    url: "https://claimshomegeneraldamage-sit.racinsurance.ractest.com.au/claims/home/general/storm-damage-claim",
  },
  {
    displayName: "How You Pay",
    url: "https://cmp-sit.racinsurance.ractest.com.au/policy/payment/how-you-pay",
  },
  {
    displayName: "Policy Cancellations",
    url: "https://pco-sit.racinsurance.ractest.com.au/Policy/Cancel",
  },
  {
    displayName: "Claims Triage - Motor",
    url: "https://ctr-sit.racinsurance.ractest.com.au/claims/motor/what-are-you-claiming-for",
  },
  {
    displayName: "Claims Triage - Home",
    url: "https://ctr-sit.racinsurance.ractest.com.au/claims/home/building-and-contents",
  },
  {
    displayName: "Motor Glass Claims",
    url: "https://claimsmotorglass-sit.racinsurance.ractest.com.au/claims/motor/glass/before-you-start",
  },
  {
    displayName: "Motor Endorsement",
    url: "https://motorendorsement-sit.racinsurance.ractest.com.au/policy/motor/manage",
  },
  {
    displayName: "Caravan Endorsement",
    url: "https://caravanendorsement-sit.racinsurance.ractest.com.au/policy/caravan/manage",
  },
  {
    displayName: "Confirm Details MFE",
    url: "https://confirmdetails-sit.racinsurance.ractest.com.au/confirmdetails",
  },
  {
    displayName: "Claims Servicing - Upload Invoice",
    url: "https://claimsservicing-sit.racinsurance.ractest.com.au/claims/servicing/invoice-or-quote",
  },
  {
    displayName: "Make A Payment",
    url: "https://makeapayment-sit.racinsurance.ractest.com.au/member/pay",
  },
];

const SPARK_APP_LINKS_UAT: ExternalLink[] = [
  {
    displayName: "Motor Collision Claims",
    url: "https://b2cuat2.ractest.com.au/claims/motor/car-accident/before-you-start",
  },
  {
    displayName: "Home General Damage Claims",
    url: "https://b2cuat2.ractest.com.au/claims/home/general/storm-damage-claim",
  },
  {
    displayName: "How You Pay",
    url: "https://b2cuat1.ractest.com.au/policy/payment/how-you-pay",
  },
  {
    displayName: "Policy Cancellations",
    url: "https://b2cuat1.ractest.com.au/Policy/Cancel",
  },
  {
    displayName: "Motor Glass Claims",
    url: "https://b2cuat2.ractest.com.au/claims/motor/glass/before-you-start",
  },
  {
    displayName: "Claims Triage - Motor",
    url: "https://b2cuat2.racinsurance.ractest.com.au/claims/motor/what-are-you-claiming-for",
  },
  {
    displayName: "Claims Triage - Home",
    url: "https://b2cuat2.racinsurance.ractest.com.au/claims/home/building-and-contents",
  },
  {
    displayName: "Caravan Endorsement",
    url: "https://b2cuat2.ractest.com.au/policy/caravan/manage",
  },
  {
    displayName: "Motor Endorsement",
    url: "https://b2cuat2.ractest.com.au/policy/motor/manage",
  },
  {
    displayName: "Claims Servicing - Upload Invoice",
    url: "https://b2cuat2.ractest.com.au/claims/servicing/invoice-or-quote",
  },
  {
    displayName: "Make A Payment",
    url: "https://b2cuat2.ractest.com.au/member/pay",
  },
];

let SPARK_APP_LINKS = SPARK_APP_LINKS_SIT;

if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  SPARK_APP_LINKS = SPARK_APP_LINKS_DEV;
} else if (process.env.REACT_APP_ENVIRONMENT === "UAT") {
  SPARK_APP_LINKS = SPARK_APP_LINKS_UAT;
}

export { SPARK_APP_LINKS };
